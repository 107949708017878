<template>
  <div class="error-input-message-wrapper" :id="idName" :class="customClassWrapper">
    <img
      src="/images/ics_f_warning_triangles_red.svg"
      alt="warning icon"
    >
    <p
      :class="customClassP"
      v-html="message"
    />
  </div>
</template>
<script>
export default {
  name: 'ErrorInputMessage',
  props: {
    message: {
      required: true,
      type: String,
      default: '',
    },
    name: {
      required: true,
      type: String,
      default: '',
    },
    customClassP: {
      type: String,
      default: '',
    },
    customClassWrapper: {
      type: String,
      default: '',
    }
  },
  computed: {
    idName() {
      return this.name && this.name !== '' ? `${this.name}-errMessage` : '';
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/style.scss';

.error-input-message-wrapper {
  margin-top: 8px;
  display: flex;
  align-items: center;
  font-size: 12px !important;
  line-height: 18px !important;
  font-weight: 400 !important;
  color: $color-red-6 !important;

  img {
    margin-right: 8px;
  }
}
</style>
