<template>
  <div class="modal-action">
    <div class="modal-action__content">
      <img
        class="modal-action__close"
        src="/images/close_modal.svg"
        alt="close.png"
        @click="closeModal"
      >
      <section>
        <img
          v-if="getSource"
          :src="getSource"
          alt="icon action"
        >
        <h1>{{ title }}</h1>
        <p>{{ desc }}</p>
      </section>
      <div class="modal-action__init">
        <Button
          v-if="buttonPrimary"
          v-bind="$attrs"
          class="button-modal-action__primary"
          :text="buttonPrimary"
          @click="$emit('btn-primary')"
        />
        <div style="width: 1.5rem;" />
        <Button
          v-if="buttonSecondary"
          v-bind="$attrs"
          class="button-modal-action__secondary"
          :text="buttonSecondary"
          type-class="outline-red"
          @click="$emit('btn-secondary')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import Button from '../new-button/Button.vue';

export default {
  name: 'ModalAction',
  components: { Button },
  mixins: [mixin],
  props: {
    icon: {
      type: String,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
    idCustom: {
      type: String,
      default: '',
    },
    buttonPrimary: {
      type: String,
    },
    buttonSecondary: {
      type: String,
    },
  },
  computed: {
    getSource() {
      return this.icon === 'confirm'
        ? '/images/modal-action/Ill_badge_confirmation-general.svg'
        : undefined;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/style.scss';
@import './style.scss';

.modal-action {
  @include overlay();

  &__content {
    @include content();

    border-radius: 12px;
    padding: 20px;
    width: 600px;
  }

  &__content section {
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: -0.24px;

    h1 {
      color: $color-base-text;
      font-weight: 700;
    }

    p {
      width: 80%;
      margin: 8px auto 16px auto;
      color: $color-text-small;
      font-weight: 400;

      @include smallScreen() {
        width: 100%;
      }
    }
  }

  &__close {
    float: right;
    text-align: center;
    position: relative;
    top: 3px;
    right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }

  &__init {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include smallScreen() {
      flex-direction: column;
    }
  }

  &__content .button-modal-action__primary,
  .button-modal-action__secondary {
    width: 50%;
    padding: 12px 16px;
    margin-right: auto;
    margin-left: auto;

    @include smallScreen() {
      width: 100%;
    }
  }

  &__content .button-modal-action__primary {
    margin: 12px auto;
  }
}
</style>
