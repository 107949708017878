<template>
  <div class="page-status-wrapper">
    <section>
      <img
        id="page-status-lp"
        src="/images/default/lp_logo_red.svg"
        alt="Lion Parcel Icon"
      >
      <img
        v-if="getStatusImage"
        id="page-status-value"
        :src="getStatusImage"
        alt="icon status"
      >
    </section>
    <section>
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
    </section>
    <Button
      v-if="buttonText && buttonText !== ''"
      id="button-page-status"
      v-bind="$attrs"
      :text="buttonText"
      @click="onClick"
    />
  </div>
</template>

<script>
import { downloadApp } from '@/misc/downloadApp';
import Button from '../new-button/Button.vue';

export default {
  name: 'PageStatus',
  components: { Button },
  props: {
    status: {
      type: String,
    },
    title: {
      type: String,
      required: true,
      default: '-',
    },
    description: {
      type: String,
      required: true,
      default: '-',
    },
    buttonText: {
      type: String,
    },
  },
  computed: {
    getStatusImage() {
      switch (this.status) {
        case 'failed':
          return '/images/failed-register.svg';
        case 'success':
          return '/images/Ill_Badge_Success.svg';
        default:
          return undefined;
      }
    },
  },
  methods: {
    onClick() {
      if (this.buttonText.toLowerCase() === 'download aplikasi') {
        downloadApp();
      }
      this.$emit('click-button');
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/style.scss';

.page-status-wrapper {
  padding: 12px 20px 20px 20px;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;

  @include smallScreen() {
    height: 100vh;
  }

  #page-status-lp {
    margin-bottom: 42px;
    width: 336px;
    height: 66px;

    @include smallScreen() {
      width: 136px;
      height: 36px;
    }
  }

  #page-status-value {
    margin-bottom: 24px;
    width: 376px;
    height: 350px;

    @include smallScreen() {
      width: 176px;
      height: 150px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  h1 {
    font-weight: 700;
    color: $color-base-text;
    margin-bottom: 32px;
  }

  p {
    color: $color-text-small;
    font-weight: 400;
    margin-bottom: 93px;
  }

  ::v-deep #button-page-status {
    padding: 12px 16px;
    width: 100%;

    @include bigScreen() {
      margin: 0 auto;
      width: 30vw;
    }

    @include tabletScreen() {
      margin: 0 auto;
      width: 40vw;
    }
  }
}
</style>
