import ProductRepository from '../api/checkShippingPrice';
import '../type/banner';

class ProductUseCase {
  constructor() {
    /** @type {ProductRepository} */
    this.api = new ProductRepository();
  }

  async getAll(q = 'page=1&per_page=15&status=ACTIVE') {
    return await this.api.getProducts(q);
  }
}

export default new ProductUseCase();
