<template>
  <div class="modal-google-maps">
    <div class="modal-google-maps__content">
      <img
        class="modal-google-maps__close"
        src="/images/close_modal.svg"
        alt="close.png"
        @click="closeModal"
      >
      <h1>{{ title }}</h1>
      <section class="modal-google-maps__content__navigator">
        <GoogleMap
          v-bind="$attrs"
          :zoom-default="lastCoordinate.zoomDefault"
          :last-coordinate="lastCoordinate"
          @saved-coordinate="saveCoordinate"
        />
      </section>
    </div>
  </div>
</template>

<script>
import GoogleMap from '../google-map/GoogleMapMarker.vue';
import mixin from './mixin';

export default {
  name: 'ModalGoogleMap',
  components: { GoogleMap },
  mixins: [mixin],
  props: {
    title: {
      type: String,
      default: 'Pilih Lokasi',
    },
    lastCoordinate: {
      type: Object,
      required: true,
    },
  },
  methods: {
    saveCoordinate(coordinate) {
      this.$emit('saved-coordinate', coordinate);
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/style.scss';
@import './style.scss';

.modal-google-maps {
  @include overlay();

  h1 {
    font-weight: 700;
    line-height: normal;

    @include smallScreen() {
      letter-spacing: -0.32px;
    }
  }

  &__content {
    @include content();

    border-radius: 12px;
    padding: 20px;
    width: 600px;

    &__navigator {
      margin-top: 1rem;
      height: 82vh;
    }
  }

  &__close {
    float: right;
    text-align: center;
    position: relative;
    top: 3px;
    right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
