<template>
  <div class="textarea-wrapper">
    <span
      v-show="label"
      class="field-title"
    >{{ label }}</span>
    <textarea
      :id="id"
      :style="customStyle"
      :class="customClass"
      v-bind="$attrs"
      :value="value"
      :placeholder="placeholder"
      :disabled="isDisabled"
      :maxlength="maxCount"
      @input="onInput"
    />
    <img
      v-if="iconImg"
      :src="iconImg"
      class="icon"
      alt="icon text area"
      @click="$emit('click-icon')"
    >
    <div
      v-show="isError"
      class="field-error-message"
    >
      <img
        :src="`/images/ics_f_warning_triangles.svg`"
        alt="Icon Warning"
      >
      <span class="error-message">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    id: {
      type: String,
      default: 'textarea',
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    maxCount: {
      type: Number,
      default: null,
    },
    isError: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    iconImg: {
      type: String,
    },
    customClass: {
      type: String,
      default: '',
    },
    customStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    onInput(event) {
      const { value } = event.target;
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
