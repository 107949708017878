import { ProductName, ProductSource } from '../enum';

/**
 *
 * @type {ProductInfoInterface[]}
 * @description command promo if the promo is not available anymore,
 * you must check the promo object on you logic
 */
export const ProductList = [
  {
    name: ProductName.BOSSPACK,
    img: ProductSource.BOSSPACK.flat,
    className: `style-product style-${ProductName.BOSSPACK.toLowerCase()}`, // you can color the text by class-name
    promo: [
      {
        title: '<p class="product-size"> <b>Kecil</b> (1-2 Kg)</p>',
        total: 'Rp10.000',
      },
      {
        title: '<p class="product-size"> <b>Sedang</b> (3-5 Kg)</p>',
        total: 'Rp29.000',
      },
      {
        title: '<p class="product-size"> <b>Besar</b> (6-9 Kg)</p>',
        total: 'Rp55.000',
      },
    ],
  },
  {
    name: ProductName.REGPACK,
    img: ProductSource.REGPACK.flat,
    className: `style-product style-${ProductName.REGPACK.toLowerCase()}`,
    promo: [
      {
        title: '<p class="product-size"> <b>Kecil</b> (1-2 Kg)</p>',
        total: 'Rp8.000',
      },
      {
        title: '<p class="product-size"> <b>Sedang</b> (3-5 Kg)</p>',
        total: 'Rp22.000',
      },
      {
        title: '<p class="product-size"> <b>Besar</b> (6-9 Kg)</p>',
        total: 'Rp42.000',
      },
    ],
  },
  {
    name: ProductName.JAGOPACK,
    img: ProductSource.JAGOPACK.flat,
    className: `style-product style-${ProductName.JAGOPACK.toLowerCase()}`,
    promo: [
      {
        title: '<p class="product-size"> <b>Kecil</b> (1-2 Kg)</p>',
        total: 'Rp7.500',
      },
      {
        title: '<p class="product-size"> <b>Sedang</b> (3-5 Kg)</p>',
        total: 'Rp20.000',
      },
      {
        title: '<p class="product-size"> <b>Besar</b> (6-9 Kg)</p>',
        total: 'Rp36.000',
      },
    ],
  },
];
