<template>
  <div class="input-radio-wrapper">
    <input
      :id="inputValue"
      v-bind="$attrs"
      :value="inputValue"
      :disabled="disabled"
      type="radio"
      :name="name"
      :title="label"
    >
    <span
      :class="['radio-circle', {'disabled': disabled}]"
      @click="$emit('change', inputValue)"
    />
    <span
      class="radio-label"
      @click="$emit('change', inputValue)"
    >{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'InputRadio',
  props: {
    name: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/css/style.scss';

@mixin makeAfterContent() {
  border: 1px solid $color-white;
  background: $color-base;
  border-radius: 9999px;
  width: 12px;
  height: 12px;
  content: "";
  @content;
}

.input-radio-wrapper {
  display: flex;
  align-items: center;

  input {
    vertical-align: middle;
    display: none;

    &:checked {
      + .radio-circle {
        border: 1px solid $color-base;

        &::after {
          @include makeAfterContent();
        }
      }
    }

    &:checked {
      + .radio-circle {
        background: $color-white;

        &::after {
          @include makeAfterContent();
        }
      }
    }
  }

  .radio-circle {
    background: $color-white;
    border: 1px solid $color-white-dark;
    display: flex;
    border-radius: 9999px;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;

    &.disabled {
      border: 1px solid $color-white-dark;
      cursor: not-allowed;
      background: rgb(211, 211, 212);
    }
  }

  .radio-label {
    font-weight: 400;
    margin-right: 50px;
    margin-left: .45rem;
  }
}
</style>
