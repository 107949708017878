<template>
  <div class="modal-introduction">
    <section class="modal-introduction__content">
      <div>
        <carousel
          ref="carousel"
          slider-dot-position="center"
          :is-hide-button="true"
          :name="carouselName"
          :total-all-data="sections.length"
          :size="sections.length"
          :shimmer="false"
          :set-default-class="true"
        >
          <template #item>
            <div
              v-for="(section, index) in sections"
              :id="`${carouselName}-${index + 1}`"
              :key="`section-${index + 1}`"
              class="slide-standalone"
            >
              <div
                :id="`id-${carouselName}-${index + 1}`"
                :class="`class-${carouselName}`"
              >
                <div class="intro-contents">
                  <img
                    v-if="section.img"
                    :src="section.img"
                    alt="icon introduction"
                  >
                  <h1>{{ section.title }}</h1>
                  <div
                    class="intro-contents__desc"
                    v-html="section.desc"
                  />
                </div>
              </div>
            </div>
          </template>
        </carousel>
      </div>
      <div class="intro-actions">
        <Button
          :text="btnPrimary"
          v-bind="$attrs"
          class="button-modal-action__primary"
          :type-class="btnSecondary ? 'outline-red' : 'red'"
          @click="clickPrimary"
        />
        <Button
          v-if="btnSecondary"
          v-bind="$attrs"
          class="button-modal-action__secondary"
          :text="btnSecondary"
          type-class="red"
          @click="next"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Carousel from '../new-carousel/Carousel.vue';
import Button from '../new-button/Button.vue';

/**
 * @typedef {Object} Section
 * @param {string} [img]
 * @param {string} title
 * @param {string} desc // you can use HTML here
 */

export default {
  name: 'ModalIntroduction',
  components: { Carousel, Button },
  props: {
    /** @type {Section[]} */
    sections: {
      type: Array,
      default: () => [],
      required: true,
    },
    nextText: {
      type: String,
      default: 'Selanjutnya',
    },
    prevText: {
      type: String,
      default: 'Sebelumnya',
    },
    doneText: {
      type: String,
      default: 'Selesai',
    },
  },
  data() {
    return {
      carouselName: 'carousel-modal-introduction',
      currentPosition: 1,
      btnPrimary: 'Selanjutnya',
      btnSecondary: undefined,
    };
  },
  methods: {
    clickPrimary() {
      if (this.currentPosition === 1) {
        this.next();
        this.btnPrimary = this.prevText;
      } else {
        this.prev();
      }
    },
    prev() {
      this.currentPosition -= 1;
      if (this.currentPosition === 1) {
        this.btnPrimary = this.nextText;
        this.btnSecondary = undefined;
      }

      if (this.currentPosition > 1) {
        this.btnSecondary = this.nextText;
      }
      this.$refs.carousel.prevPage();
    },
    next() {
      this.currentPosition += 1;
      if (this.currentPosition === this.sections.length) {
        this.btnSecondary = this.doneText;
      }

      if (this.currentPosition > this.sections.length) {
        this.$emit('done', true);
      }

      if (this.currentPosition > 1 && this.currentPosition < this.sections.length) {
        this.btnPrimary = this.prevText;
        this.btnSecondary = this.nextText;
      }
      this.$refs.carousel.nextPage();
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/assets/css/style.scss';
@import './style.scss';

::v-deep #slider-dots {
  position: relative;
  z-index: 1;

  > span {
    z-index: -1;
  }
}

::v-deep .slides {
  overflow-x: hidden !important;
}

.modal-introduction {
  @include overlay();

  &__content {
    @include content() {
      border-radius: 12px;
      width: 600px;
    }

    .intro-contents {
      padding: 20px 20px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-align: center;

      h1 {
        font-size: 18px;
        margin: 12px 0 8px 0;
        color: $color-base-text;
        font-weight: 700;

        @include smallScreen() {
          font-size: 16px;
        }
      }

      &__desc {
        font-weight: 400;
        color: $color-text-small;
        font-size: 14px;
        width: 90%;

        @include smallScreen() {
          font-size: 12px;
        }
      }
    }

    .intro-actions {
      margin-top: 16px;
      padding: 0 20px 20px 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      .button-modal-action__primary {
        width: 100%;

        ~ .button-modal-action__secondary {
          margin-left: 1rem;
          width: 100%;
        }
      }
    }
  }

  &__content .button-modal-action__primary,
  .button-modal-action__secondary {
    padding: 12px 16px;
    margin-right: auto;
    margin-left: auto;
  }
}
</style>
