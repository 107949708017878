import CheckShippingPriceAlgoRepository from '../api/checkShippingPriceAlgo';

class CheckShippingPriceAlgoUseCase {
  constructor() {
    this.api = new CheckShippingPriceAlgoRepository();
  }

  /**
   *
   * @param payload {RequestCheckPriceAlgo}
   * @returns {Promise<{data: (*|Array<ResponseCheckPriceAlgo>), err: null}|{data: null, err: (*|null)}>}
   */
  async requestShippingPrice(payload) {
    return await this.api.requestShippingPrice(payload);
  }

}

export default new CheckShippingPriceAlgoUseCase();
