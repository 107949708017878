<template>
  <transition name="bounce">
    <div
      ref="errorAlert"
      class="error-alert"
    >
      <div class="error-alert__card">
        <div class="error-alert__card__desc">
          <p>{{ errorAlertMessage }}</p>
        </div>
        <div
          v-if="hasCloseButton"
          class="error-alert__card__close"
          @click="() => $emit('close')"
        >
          <img
            src="/images/x.svg"
            alt=""
          >
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    errorAlertMessage: {
      type: String,
      default: '',
    },
    hasCloseButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isErrorAlertOpen: true,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/css/style.scss';

.error-alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  z-index: 99999;

  &__card {
    width: 328px;
    padding: 16px;
    border-radius: 8px;
    background-color: $color-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    position: relative;

    &__desc {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      p {
        margin-top: 0;
        color: white;
      }
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      position: absolute;
      right: 5px;
    }
  }

  @media (min-width: 771px) and (max-width: 1045px) {
    top: 28px;
  }
}

.bounce-enter-active {
  animation: bounce-in 0.2s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  100% {
    transform: translateY(2%);
    opacity: 1;
  }
}
</style>

