<template>
  <div class="input-phone-international-wrapper">
    <div
      v-if="isDropdownVisible"
      class="backdrop-overlay"
      @click="onClickOutside"
    />
    <div class="input-phone-international">
      <div
        class="input-phone-international__input-wrapper"
        :class="isError ? 'error' : ''"
      >
        <div
          class="input-phone-international__input-wrapper__prefix-dropdown"
          @click="onClickDropdown"
        >
          <div
            class="input-phone-international__input-wrapper__prefix-dropdown-prefix"
          >
            {{ getSelectedPhoneCode }}
          </div>
          <div
            class="input-phone-international__input-wrapper__prefix-dropdown-chevron"
            :class="isDropdownVisible ? 'chevron-up' : ''"
          >
            <img
              :src="`/images/chevron_down.svg`"
              alt="arrow down"
            >
          </div>
        </div>
        <input
          ref="inputPhoneNumber"
          v-model="displayValue"
          tabindex="0"
          v-bind="$attrs"
          class="input-phone-international__input-wrapper__input input-breakpoint-style"
          type="text"
          :placeholder="placeholder"
          @change="$emit('change', $event)"
        >
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="isDropdownVisible"
        class="input-phone-international__dropdown-wrapper"
      >
        <div class="input-phone-international__dropdown-wrapper__search-wrapper">
          <input
            v-model="keyword"
            v-bind="$attrs"
            class="input-phone-international__dropdown-wrapper__search-wrapper__input"
            type="text"
            :placeholder="searchPlaceholder"
          >
          <div
            class="input-phone-international__dropdown-wrapper__search-wrapper__chevron"
            :class="isDropdownVisible ? 'chevron-up' : ''"
          >
            <img
              :src="`/images/chevron_down.svg`"
              alt="arrow down"
            >
          </div>
        </div>
        <div
          class="wrapper-options-phone-code"
        >
          <div
            v-for="(item, index) in filteredOptions"
            :key="index"
            class="wrapper-options-phone-code__card"
            @click="() => onClickPhoneCode(item)"
          >
            <div
              class="wrapper-options-phone-code__card-country"
              :class="
                getSelectedPhoneCode === item.phoneCode
                  ? 'wrapper-options-phone-code__card-country-selected'
                  : ''
              "
            >
              {{ item.country }}
            </div>
            <div
              class="wrapper-options-phone-code__card-country"
              :class="
                getSelectedPhoneCode === item.phoneCode
                  ? 'wrapper-options-phone-code__card-country-selected'
                  : ''
              "
            >
              {{ item.phoneCode }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'InputPhoneInternational',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: 'Cari Kode Negara',
    },
    placeholder: {
      type: String,
      default: 'Eg: 81367722112',
    },
    phoneCode: {
      type: String,
    },
    customClass: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDropdownVisible: false,
      keyword: '',
      selectedPhoneCode: {
        country: 'Indonesia',
        phoneCode: '+62',
      },
      phoneCodeList: [
        {
          country: 'Indonesia',
          phoneCode: '+62',
        },
        {
          country: 'Australia',
          phoneCode: '+61',
        },
        {
          country: 'Brunei',
          phoneCode: '+673',
        },
        {
          country: 'Cambodia',
          phoneCode: '+855',
        },
        {
          country: 'Canada',
          phoneCode: '+1',
        },
        {
          country: 'France',
          phoneCode: '+33',
        },
        {
          country: 'Germany',
          phoneCode: '+49',
        },
        {
          country: 'Hongkong',
          phoneCode: '+852',
        },
        {
          country: 'India',
          phoneCode: '+91',
        },
        {
          country: 'Jepang',
          phoneCode: '+81',
        },
        {
          country: 'Korea Selatan',
          phoneCode: '+82',
        },
        {
          country: 'Macau',
          phoneCode: '+853',
        },
        {
          country: 'Malaysia',
          phoneCode: '+60',
        },
        {
          country: 'Netherlands',
          phoneCode: '+31',
        },
        {
          country: 'New Zealand',
          phoneCode: '+64',
        },
        {
          country: 'Philippines',
          phoneCode: '+63',
        },
        {
          country: 'Qatar',
          phoneCode: '+974',
        },
        {
          country: 'Saudi Arabia',
          phoneCode: '+966',
        },
        {
          country: 'Singapore',
          phoneCode: '+65',
        },
        {
          country: 'Taiwan',
          phoneCode: '+886',
        },
        {
          country: 'Thailand',
          phoneCode: '+66',
        },
        {
          country: 'Turkey',
          phoneCode: '+90',
        },
        {
          country: 'Uni Arab Emirate',
          phoneCode: '+971',
        },
        {
          country: 'United Kingdom',
          phoneCode: '+44',
        },
        {
          country: 'United State of America',
          phoneCode: '+1',
        },
        {
          country: 'Vietnam',
          phoneCode: '+84',
        },
      ],
    };
  },
  computed: {
    filteredOptions() {
      return this.onFilterOptions(this.phoneCodeList, this.keyword);
    },
    displayValue: {
      get() {
        return this.numberOnly(this.value);
      },
      set(value) {
        const parseNumberOnly = this.numberOnly(value);
        const inputElement = this.$refs.inputPhoneNumber;
        inputElement.value = parseNumberOnly;
        this.$emit('input', parseNumberOnly);
      },
    },
    getSelectedPhoneCode() {
      return this.phoneCode || this.selectedPhoneCode.phoneCode;
    },
  },
  methods: {
    numberOnly(value) {
      return value.replace(/[^0-9]+/g, '');
    },
    onClickDropdown() {
      if (!this.disabled) {
        this.isDropdownVisible = !this.isDropdownVisible;
        this.keyword = '';
      }
    },
    onClickOutside() {
      this.keyword = '';
      this.isDropdownVisible = false;
    },
    swapFirstPhoneCodeList() {
      const selectedPhoneCodeIndex = this.phoneCodeList.findIndex(
        item => item.phoneCode === this.selectedPhoneCode.phoneCode,
      );
      if (selectedPhoneCodeIndex !== -1) {
        const selectedPhoneCode = this.phoneCodeList[selectedPhoneCodeIndex];
        this.phoneCodeList.splice(selectedPhoneCodeIndex, 1);
        this.phoneCodeList.unshift(selectedPhoneCode);
      }
    },
    onClickPhoneCode(item) {
      this.selectedPhoneCode = item;
      this.isDropdownVisible = false;
      this.$emit('onPhoneCodeChange', item.phoneCode);
      this.swapFirstPhoneCodeList();
      this.keyword = '';
    },
    onFilterOptions(options, keyword) {
      return options.filter(item => item.country.toLowerCase().startsWith(keyword.toLowerCase()));
    },
  },
};
</script>

<style scoped lang="scss" src="./style.scss"></style>
