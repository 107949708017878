import '../type/checkPriceAlgo';
import CustomerAppAPI from '../customerAppAxios';

export default class CheckShippingPriceAlgoRepository extends CustomerAppAPI {
  constructor() {
    super();
    /** @type {string} */
    this.version = 'v5';
    this.endpoint = 'shipment/elexys/tariff';
  }

  /**
   *
   * @param payload {RequestCheckPriceAlgo}
   * @returns {Promise<{data: null, err: *}|{data: Array<ResponseCheckPriceAlgo>, err: null}|{data: null, err: null}>}
   */
  async requestShippingPrice(payload) {
    try {
      /** @type {import('axios').AxiosResponse<Object>} */
      const res = await this.$axios.post(`${this.version}/${this.endpoint}`, payload);
      if (res.status === 200) {
        if (res.data) {
          return { data: res.data.data, err: null };
        }
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err) };
    }
  }
}
