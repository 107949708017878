<template>
  <div style="height: 100%;">
    <Alert
      v-if="alertInfo !== ''"
      :error-alert-message="alertInfo"
    />
    <template v-if="isLoadingMap">
      <Loader
        position-left="45%"
        color-class="l-grey"
      />
    </template>
    <template v-else>
      <div :id="idName">
        <h1 v-if="isGoogleMapNotActive">
          Maaf, saat ini Google Maps kami tidak bisa digunakan
        </h1>
      </div>
    </template>
  </div>
</template>

<script>
import Loader from '@/components/new-loader/Loader.vue';
import Alert from '../alert/Alert.vue';

export default {
  name: 'GoogleMap',
  components: { Alert, Loader },
  props: {
    zoomDefault: {
      type: Number,
      default: 18,
    },
    lastCoordinate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      coordinate: {
        placeId: '',
        fullAddress: '',
        label: '',
        lat: '',
        lng: '',
      },
      isLoadingMap: false,
      alertInfo: '',
      durationAlert: 3000,
      durationIntervalElem: 100,
      isGoogleMapNotActive: false,
      idName: 'google-map-lp',
      isLoadingFetchData: false,
      /** @type {GoogleMapsLatLngInterface | null} */
      latLngPinned: null,
      /**
       * @type {GoogleMapsLatLngInterface}
       * @description default location in Lion Parcel HQ Kedoya, if user not allow to get location
       * */
      latLngDefault: {
        lat: -6.189603,
        lng: 106.7653573,
      },
      /** @type {GoogleMapsInstance | null} */
      map: null,
      /** @type {GoogleMapsMarkerInstance | null} */
      clientMarker: null,
      /** @type {GoogleMapsMarkerInstance | null} */
      clientMarkerPinned: null,
      listener: null,
      /** @type {GoogleMapsMarkerLabelInterface} */
      labelMarker: {
        text: 'Pilih Lokasi Ini',
        fontSize: '12px',
        fontWeight: '600',
        className: 'pinned-location',
      },
      classCoordinate: 'custom-coordinate-wrapper',
      classCoordinateInfo: 'custom-coordinate-wrapper__info',
      classControl: 'custom-map-control-button',
      classControl2: 'custom-map-control-button__2',
      idBtnSave: 'custom-coordinate-btn-save',
      elementButton: null,
      elementButton2RemoveListener: true,
      elementButtonSaveRemoveListener: true,
      isLoadingGetLocation: false,
      isInitDefault: 0,
      elemBtnSave: null,
    };
  },
  computed: {
    hasLastCoordinate() {
      return this.lastCoordinate && this.lastCoordinate.lat && this.lastCoordinate.lng;
    },
  },
  created() {
    if (process.env.VUE_APP_API_GOOGLE_MAPS_ACTIVE === 'true') {
      if (window.google && 'maps' in window.google) {
        this.initMap();
        this.setMarkers();
        this.isLoadingMap = false;
      } else {
        this.isLoadingMap = true;
      }
    } else {
      this.isGoogleMapNotActive = true;
    }
  },
  beforeDestroy() {
    this.listeningEvents(true);
  },
  methods: {
    async initMap() {
      const { Map } = await window.google.maps.importLibrary('maps');

      const pos = {
        lat: this.latLngDefault.lat,
        lng: this.latLngDefault.lng,
      };

      if (this.hasLastCoordinate) {
        pos.lat = this.lastCoordinate.lat;
        pos.lng = this.lastCoordinate.lng;
      }

      /** @type {GoogleMapsOptionsInterface} */
      const opt = {
        center: pos,
        zoom: 8,
        fullscreenControl: true,
        disableDefaultUI: true,
        mapId: process.env.VUE_APP_API_GOOGLE_MAPS_ID,
        keyboardShortcuts: window.innerWidth > 900,
      };

      const elemMap = document.getElementById(this.idName);
      this.map = new Map(elemMap, opt);

      this.elementButton = this.createButtonBackToTheFirstPosition();
      this.elementButton.addEventListener('click', this.goToTheCoordinate);
      this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(this.elementButton);

      // add coordinate info box on the bottom of map
      // display none at first, will show when client click the pinned marker
      const coordinateInfo = document.createElement('div');
      coordinateInfo.innerHTML = `
        <section>
            <div>
                 ${this.createButtonBackToTheFirstPosition(this.classControl2).outerHTML}
            </div>
        <div class="${this.classCoordinateInfo}">
            <h3>Lokasi sesuai titik</h3>
        </div>
        <button id="${this.idBtnSave}"> Simpan </button>
        </section>
        `;
      coordinateInfo.classList.add(this.classCoordinate);

      this.map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(coordinateInfo);
    },
    goToTheCoordinate() {
      const pos = {
        lat: this.clientMarker.getPosition().lat(),
        lng: this.clientMarker.getPosition().lng(),
      };

      if (this.clientMarker !== null) {
        this.clientMarkerPinned.setPosition(pos);
        this.map.setCenter(pos);
      } else {
        this.clientMarkerPinned.setPosition(this.latLngDefault);
        this.map.setCenter(this.latLngDefault);
      }
    },
    saveCoordinate() {
      let mainCoordinate = this.coordinate;
      if (this.hasLastCoordinate && this.coordinate.lat === '') {
        mainCoordinate = this.lastCoordinate;
      }
      mainCoordinate.zoomDefault = this.map.getZoom();
      this.$emit('saved-coordinate', mainCoordinate);
      // you need to set lastCoordinate on your parent component when client click save button
    },
    createButtonBackToTheFirstPosition(className = this.classControl) {
      const locationButton = document.createElement('button');
      locationButton.innerHTML = '<img src="https://storage.googleapis.com/lionparcel-web-stg/assets/pan-my-location.png" alt="center location" width="40"/>';
      locationButton.classList.add(className);
      return locationButton;
    },
    setMarkerCurrentLocation(MarkerInstance, animation, isInitFirstTome) {
      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition((position) => {
          /** @type {GoogleMapsLatLngInterface} */
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          /** @type {GoogleMapsMarkerOptionsInterface} */
          const optsMarkerClient = {
            position: pos,
            map: this.map,
            title: 'Your Current Location',
            animation: animation.DROP,
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              fillColor: 'blue',
              fillOpacity: 1,
              strokeColor: 'white',
              strokeWeight: 2,
              scale: 8, // Adjust the size of the centered pin
            },
            zIndex: 1,
          };

          /** @type {GoogleMapsMarkerInstance} */
          this.clientMarker = new MarkerInstance(optsMarkerClient);

          setTimeout(() => {
            this.clientMarker.setAnimation(animation.BOUNCE);
          }, 500);

          this.map.setCenter(pos);
          this.map.setZoom(this.zoomDefault);

          if (this.hasLastCoordinate) {
            const posLastCoordinate = {
              lat: this.lastCoordinate.lat,
              lng: this.lastCoordinate.lng,
            };

            this.clientMarkerPinned.setPosition(posLastCoordinate);
            this.map.setCenter(posLastCoordinate);
          }
          resolve(pos);
        }, () => { // error callback
          this.getCurrentLocationToInfoBox(this.clientMarkerPinned, isInitFirstTome);
        }, { timeout: 5000, enableHighAccuracy: true });
      });
    },
    makeTemplateInfoCoordinate(isInitFirstTime = false, place) {
      const classCoordinateInfo = `.${this.classCoordinateInfo}`;

      if (isInitFirstTime) {
        // because the load map need time, we need to keep search the element
        this.durationIntervalElem = setInterval(() => {
          const boxInfo = document.querySelector(classCoordinateInfo);

          if (boxInfo) {
            boxInfo.innerHTML = `
                      <h3>Lokasi sesuai titik</h3>
                      <h1>${place.name}</h1>
                      <p>${place.formatted_address}</p>
                    `;
            this.changeBoxCoordinateInfo(true);
            clearInterval(this.durationIntervalElem);
          }
        }, this.durationIntervalElem);
      } else {
        const boxInfo = document.querySelector(classCoordinateInfo);

        if (boxInfo) {
          boxInfo.innerHTML = `
                      <h3>Lokasi sesuai titik</h3>
                      <h1>${place.name}</h1>
                      <p>${place.formatted_address}</p>
                    `;
        }
        this.changeBoxCoordinateInfo(true);
      }
    },
    getCurrentLocationToInfoBox(MarkerInstance = this.clientMarkerPinned, isInitFirstTime = false) {
      clearTimeout(this.durationAlert);

      const pos = {
        lat: MarkerInstance.getPosition().lat(),
        lng: MarkerInstance.getPosition().lng(),
      };

      const geocoder = new window.google.maps.Geocoder();

      this.coordinate.lat = pos.lat;
      this.coordinate.lng = pos.lng;

      geocoder.geocode({ location: pos }, async (results, status) => {
        if (status === 'OK') {
          if (results && results.length > 0) {
            // search the location by place id to get the detail
            const { PlacesService } = await window.google.maps.importLibrary('places');
            const request = {
              placeId: results[0].place_id,
              fields: ['name', 'formatted_address', 'place_id', 'geometry'],
            };
            const placeService = new PlacesService(this.map);

            await placeService.getDetails(request, (place, statusPlace) => {
              if (
                statusPlace === window.google.maps.places.PlacesServiceStatus.OK
                  && place
                  && place.geometry
                  && place.geometry.location
              ) {
                this.makeTemplateInfoCoordinate(isInitFirstTime, place);

                this.coordinate.fullAddress = place.formatted_address;
                this.coordinate.placeId = place.place_id;
                this.coordinate.label = place.name;
              } else {
                this.alertInfo = 'Maaf, terjadi kesalahan saat mengambil lokasi Anda';
              }
            });
          } else {
            this.alertInfo = 'Maaf, kami tidak bisa mendapatkan lokasi Anda';
          }
        } else {
          this.alertInfo = 'Maaf, kami tidak bisa mendapatkan titik lokasi Anda';
        }

        setTimeout(() => {
          this.alertInfo = '';
        }, this.durationAlert);
      });
    },
    async setMarkers() {
      const { Marker, Animation } = await window.google.maps.importLibrary('marker');

      /** @type {GoogleMapsMarkerOptionsInterface} */
      const optsMarkerClientPinned = {
        position: this.latLngDefault,
        map: this.map,
        title: 'Your Pinned Location',
        animation: Animation.DROP,
        draggable: true,
        icon: {
          url: 'https://storage.googleapis.com/lionparcel-web-stg/assets/pin-point-icon.png',
          labelOrigin: {
            x: 18,
            y: 54.5,
          },
          size: { // make the label bottom clickable
            height: 65,
            width: 32,
          },
        },
        zIndex: 2,
      };
      this.map.setZoom(this.zoomDefault);
      this.isInitDefault += 1;

      if (this.hasLastCoordinate) {
        optsMarkerClientPinned.position = {
          lat: this.lastCoordinate.lat,
          lng: this.lastCoordinate.lng,
        };
      }

      /** @type {GoogleMapsMarkerInstance} */
      this.clientMarkerPinned = new Marker(optsMarkerClientPinned);

      // register events
      this.listeningEvents();

      // get location of client
      // then make the markers
      const getCurrent = () => {
        if (this.hasLastCoordinate) {
          this.makeTemplateInfoCoordinate(true, {
            name: this.lastCoordinate.label,
            formatted_address: this.lastCoordinate.fullAddress,
          });
        } else {
          this.getCurrentLocationToInfoBox(this.clientMarkerPinned, true);
        }
      };

      this.setMarkerCurrentLocation(Marker, Animation, true)
        .then((res) => {
          optsMarkerClientPinned.position = res;
          getCurrent();
        });
    },
    setElemStyleWhenTriggerFullScreen(boxInfo) {
      let isFullScreenValue = false;
      if (
        this.map
          && this.map.getDiv().firstChild.clientHeight === window.innerHeight
          && this.map.getDiv().firstChild.clientWidth === window.innerWidth
          && window.innerWidth > 900 // only desktop
      ) {
        isFullScreenValue = true;
        if (boxInfo) {
          // set center on full screen
          boxInfo.style.transform = 'translateX(-50%)';
          boxInfo.style.left = '50%';
          boxInfo.style.bottom = '0';
          boxInfo.style.width = '40%';
        }
      } else {
        isFullScreenValue = false;
        if (boxInfo) {
          boxInfo.style.transform = 'none';
          boxInfo.style.left = '0';
          boxInfo.style.width = '100%';
        }
      }
      return isFullScreenValue;
    },
    listenerWhenMapMoveCenter() {
      const getCenter = this.map.getCenter();

      if (getCenter) {
        this.clientMarkerPinned.setLabel('');
        this.clientMarkerPinned.setPosition(getCenter);
        this.changeBoxCoordinateInfo(false);

        const btnControl = document.querySelector(`.${this.classControl}`);

        if (btnControl) {
          btnControl.style.display = 'flex';
        }
      }
    },
    listenerWhenPanAndZoom() {
      if (this.isInitDefault > 1) {
        this.clientMarkerPinned.setLabel(this.labelMarker);
      }
      this.isInitDefault += 1;
    },
    listenerWhenViewportChange() {
      const boxInfo = document.querySelector(`.${this.classCoordinate}`);
      const btnControl = document.querySelector(`.${this.classControl}`);

      let isFulScreen = false;
      if (boxInfo && btnControl) {
        setTimeout(() => {
          if (isFulScreen) {
            // set center on full screen
            boxInfo.style.left = '50%';
          } else {
            // set center on initial screen
            boxInfo.style.left = '0';
          }

          // make the button back to first position keep on the right bottom
          btnControl.style.right = '0';
          btnControl.style.bottom = '14px';
        }, 300);
      }

      isFulScreen = this.setElemStyleWhenTriggerFullScreen(boxInfo);
    },
    listenerWhenDragged() {
      this.clientMarkerPinned.setLabel('');
      this.changeBoxCoordinateInfo(false);
    },
    listenerWhenDraggedStop() {
      this.clientMarkerPinned.setLabel(this.labelMarker);
    },
    listenerPinnedOnClick() {
      this.getCurrentLocationToInfoBox();
    },
    listeningEvents(isRemoveListener = false) {
      // when client pan or zoom in or zoom out on Map to center the map
      const center = window.google.maps.event.addListener(this.map, 'center_changed', this.listenerWhenMapMoveCenter);

      // when client zoom in or zoom out or pan on Map
      const idle = window.google.maps.event.addListener(this.map, 'idle', this.listenerWhenPanAndZoom);

      // when client resize the window
      const bounds = window.google.maps.event.addListener(this.map, 'bounds_changed', this.listenerWhenViewportChange);

      // when client drag the pinned marker
      const doDrag = this.clientMarkerPinned.addListener('drag', this.listenerWhenDragged);

      // when client stop drag the pinned marker
      const dragEnd = this.clientMarkerPinned.addListener('dragend', this.listenerWhenDraggedStop);

      // when client click the pinned marker
      const clickAble = window.google.maps.event.addListener(this.clientMarkerPinned, 'click', this.listenerPinnedOnClick);

      if (isRemoveListener) {
        center.remove();
        idle.remove();
        bounds.remove();
        doDrag.remove();
        dragEnd.remove();
        clickAble.remove();
      }
    },
    changeBoxCoordinateInfo(isShow) {
      const boxInfo = document.querySelector(`.${this.classCoordinate}`);
      const btnControl = document.querySelector(`.${this.classControl}`);
      const btnControl2 = document.querySelector(`.${this.classControl2}`);
      this.elemBtnSave = document.querySelector(`#${this.idBtnSave}`);

      // add click listener button on the top of info box
      if (btnControl2 && isShow) {
        btnControl2.addEventListener('click', this.goToTheCoordinate);
        this.elementButton2RemoveListener = false;
      }

      // add click listener button on the top of info box
      if (this.elemBtnSave && isShow) {
        this.elemBtnSave.addEventListener('click', this.saveCoordinate);
        this.elementButtonSaveRemoveListener = false;
      }

      if (boxInfo && btnControl) {
        // set center on initial screen
        boxInfo.style.left = '0';
        boxInfo.style.display = isShow ? 'flex' : 'none';
        btnControl.style.display = isShow ? 'none' : 'block';

        btnControl.style.right = '0';
        btnControl.style.bottom = '14px';

        // display box info of client location when client click the pinned marker
        if (isShow) {
          boxInfo.style.transform = 'none';
          this.clientMarkerPinned.setLabel('');
        }

        this.setElemStyleWhenTriggerFullScreen(boxInfo);
      }

      if (!this.elementButton2RemoveListener && !isShow && btnControl2) {
        btnControl2.removeEventListener('click', this.goToTheCoordinate);
        this.elementButton2RemoveListener = true;
      }

      if (!this.elementButton2RemoveListener && !isShow && this.elemBtnSave) {
        this.elemBtnSave.removeEventListener('click', this.saveCoordinate);
        this.elementButtonSaveRemoveListener = true;
      }
    },
  },
};

</script>
<style scoped lang="scss">
@import '@/assets/css/style.scss';

/* hide styles of Google Maps */
::v-deep img[alt="Google"] {
  display: none !important;
}

::v-deep .gm-style-cc div a {
  display: none !important;
}

::v-deep .gmnoprint a, .gmnoprint span {
  display: none;
}

::v-deep .gmnoprint div {
  display: none;
  background: none !important;
}

#google-map-lp {
  height: 100%;

  h1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

::v-deep .pinned-location {
  border-radius: 12px;
  border: 1px solid $color-base !important;
  background-color: white !important;
  padding: 4px 12px 4px 12px !important;
  color: $color-base !important;
}

::v-deep .custom-map-control-button {
  font-family: "Montserrat", sans-serif;
  background-color: transparent;
  border: 0;
  overflow: hidden;
  cursor: pointer;
}

::v-deep .custom-coordinate-wrapper {
  font-family: "Montserrat", sans-serif;
  color: $color-base-text;
  flex-direction: column;
  background: transparent;
  display: none;
  overflow: hidden;
  margin-bottom: 7px;
  width: 100%;
  padding: 0 .85rem;
  justify-content: center;
  align-items: center;
  align-content: center;

  section {
    width: 100%;

    button {
      text-align: right;
      width: 100%;
    }
  }

  .custom-map-control-button__2 {
    background-color: transparent;
    border: 0;
    overflow: hidden;
    cursor: pointer;
  }

  &__info {
    background: white;
    border: 1px solid $color-white-dark;
    border-radius: 4px;
    padding: 8px 12px;
    margin-bottom: 7px;

    h3 {
      color: $color-text-small;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
    }

    h1 {
      margin: 4px 0;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.24px;
    }
  }

  #custom-coordinate-btn-save {
    font-family: "Montserrat", sans-serif;
    color: white;
    display: inline-flex;
    cursor: pointer;
    padding: 11.5px 16px;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 6px;
    background: $color-base;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    position: relative;
    box-shadow: none;
    user-select: none;
  }
}
</style>
